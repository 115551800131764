import React from 'react';
import PropTypes from 'prop-types';

/**
 * todo:
 * - calculate intrinsic dimensions of image without using <img> height and width attributes https://amp.dev/documentation/guides-and-tutorials/learn/amp-html-layout/layouts_demonstrated/?format=websites#intrinsic
 * - automatically generate a srcset attribute, if one isn't passed, based on style system breakpoints
 * - figure out a better way to set CDN_URL. context? requires wrapping of app in provider... this might be ok if we ever allow theming. other options?
 */

let CDN_URL;

// CRA and Next.js

if (typeof process !== 'undefined') {
  CDN_URL = process.env.REACT_APP_CDN_URL ?? process.env.NEXT_PUBLIC_CDN_URL ?? 'https://cdn.britannica.com';
}

// Mendel and a default (this can't be a long term solution :grimace:)
else {
  CDN_URL = window.Mendel.config.cdnUrl ?? 'https://cdn.britannica.com';
}

const IrisImage = ({
  children,
  src,
  alt,
  loading,
  quality,
  height,
  width,
  size,
  command,
  imgHeight,
  imgWidth,
  ...rest
}) => {
  const irisParams = [];

  if (command) {
    irisParams.push(`c:${command}`);
  }

  if (height) {
    irisParams.push(`h:${height}`);
  }

  if (width) {
    irisParams.push(`w:${width}`);
  }

  if (size) {
    irisParams.push(`s:${size}`);
  }

  if (quality) {
    irisParams.push(`q:${quality}`);
  }

  const irisParamString = irisParams.length === 0 ? '' : `/${irisParams.join(',')}`;
  const computedSrc = CDN_URL + irisParamString + src;

  return (
    <>
      <img loading={loading} src={computedSrc} alt={alt} height={imgHeight} width={imgWidth} {...rest} />
      {children?.({ computedSrc })}
    </>
  );
};

IrisImage.propTypes = {
  children: PropTypes.func,
  alt: PropTypes.string.isRequired,
  command: PropTypes.oneOf(['crop']),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.string,
  quality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

IrisImage.defaultProps = {
  children: null,
  command: null,
  height: null,
  imgHeight: null,
  imgWidth: null,
  loading: 'lazy',
  quality: null,
  size: null,
  width: null,
};

export default IrisImage;
