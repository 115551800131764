import { Close } from '@material-ui/icons';
import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const ModalHeader = ({ children, className, toggle, ...props }) => (
  <div className={classnames(className, 'modal-header')} data-testid="ModalHeader" {...props}>
    {children}
    <button type="button" className="close p-10 btn btn-link" onClick={toggle}>
      <Close />
    </button>
  </div>
);

ModalHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

ModalHeader.defaultProps = {
  children: null,
  className: null,
};

export default ModalHeader;
