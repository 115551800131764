import { KeyboardArrowRight } from '@material-ui/icons';
import classnames from 'classnames';
import React, { Children } from 'react';
import PropTypes from 'prop-types';

const Breadcrumb = ({ children, className, tag: Tag, ...rest }) => (
  <Tag className={classnames(className, 'breadcrumb')} data-testid="Breadcrumb" {...rest}>
    {Children.map(children, (child, i) => (
      <>
        {child}
        {i !== Children.count(children) - 1 && (
          <KeyboardArrowRight fontSize="inherit" className="mx-5 align-self-center" />
        )}
      </>
    ))}
  </Tag>
);

Breadcrumb.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

Breadcrumb.defaultProps = {
  children: null,
  className: null,
  tag: 'nav',
};

export default Breadcrumb;
